import { ref, computed } from 'vue'
import type {Ref} from 'vue';

export function useFilterableSearchPage(filterData: Ref<{ [key: string]: any }>) {
    const {
        handleApplyingFilter,
        handleSearchInputFocus,
        handleSearchInputBlur
    } = useSearchPage();

    const inputFilterData: Ref<{ [key: string]: any }> = ref({...filterData.value});

    const filterExists = computed(() => {
        const filterProps = Object.keys(filterData.value).filter(key => !['search', 'order', 'dir'].includes(key));

        return filterProps.some(key => filterData.value[key] && filterData.value[key].length);
    });

    function resetFilter(key: string|string[]|null, callback: (() => void) | null = null) {
        const keys: string[] = key && !Array.isArray(key) ? [key] : ((key as string[]) || Object.keys(inputFilterData.value));

        keys.forEach(itKey => {
            inputFilterData.value[itKey] = Array.isArray(inputFilterData.value[itKey]) ? [] : null;
            handleSubmittedFilter(itKey);
        })

        if (callback) {
            callback();
        }
    }

    function unsetFilter(key: string|string[]|null) {
        const keys: string[] = key && !Array.isArray(key) ? [key] : ((key as string[]) || Object.keys(inputFilterData.value));
        keys.forEach(itKey => {
            inputFilterData.value[itKey] = Array.isArray(inputFilterData.value[itKey]) ? [] : null;
        })
    }


    function handleSubmittedFilter(key: string|string[]|null) {
        if (key) {
            const keys = !Array.isArray(key) ? [key] : key;
            keys.forEach(itKey => {
                filterData.value[itKey] = inputFilterData.value[itKey] && Array.isArray(inputFilterData.value[itKey]) ? [...inputFilterData.value[itKey]] : inputFilterData.value[itKey];
            })
        } else {
            filterData.value = {...inputFilterData.value};
        }
    }

    function handleOpenedFilter(key: string|string[]|null = null) {
        const keys: string[] = key && !Array.isArray(key) ? [key] : ((key as string[]) || Object.keys(filterData.value));
        keys.forEach(itKey => {
            inputFilterData.value[itKey] = filterData.value[itKey] && Array.isArray(filterData.value[itKey]) ? [...filterData.value[itKey]] : filterData.value[itKey];
        })
    }

    return {
        filterData,
        inputFilterData,
        filterExists,

        handleApplyingFilter,
        handleSearchInputFocus,
        handleSearchInputBlur,
        resetFilter,
        unsetFilter,
        handleSubmittedFilter,
        handleOpenedFilter
    }
}