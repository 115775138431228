import { ref, computed } from 'vue'
import type {Ref} from 'vue';
import {useFilterableSearchPage} from "./useFilterableSearchPage";
import type {LatLng} from "@spoferan/spoferan-ts-core";
import {GeolocationService} from "@spoferan/nuxt-spoferan/services";

export function useMainModelSearchPage(filterData: Ref<{ [key: string]: any }>) {
    const {
        inputFilterData,
        filterExists,
        handleApplyingFilter,
        handleSearchInputFocus,
        handleSearchInputBlur,
        resetFilter,
        unsetFilter,
        handleSubmittedFilter,
        handleOpenedFilter
    } = useFilterableSearchPage(filterData);

    const deviceCoordinates: Ref<LatLng|null> = ref(null);

    function refreshDeviceCoordinates() {
        GeolocationService.locate((latLng: LatLng) => {
            deviceCoordinates.value = latLng
        });
    }

    return {
        filterData,
        inputFilterData,
        filterExists,
        deviceCoordinates,

        handleApplyingFilter,
        handleSearchInputFocus,
        handleSearchInputBlur,
        resetFilter,
        unsetFilter,
        handleSubmittedFilter,
        handleOpenedFilter,
        refreshDeviceCoordinates
    }
}